import $ from 'jquery';
export default class CookieConsentActions {
    static init() {
        $('.js__revoke-cookie-preferences').on('click', event => {
            event.preventDefault();
            if ('OneTrust' in window) {
                // @ts-ignore
                window.OneTrust.ToggleInfoDisplay();
            }
        });
        this.initCallbackToRemoveCookieBannerTabIndex();
        this.removeCookieBannerTabIndex();
    }
    static onCookieConsentAdded(callback) {
        const config = { childList: true, subtree: true };
        const observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if ($(mutation.addedNodes).filter('#onetrust-consent-sdk').length > 0) {
                    callback();
                    observer.disconnect();
                    break;
                }
            }
        });
        observer.observe($('body').get(0), config);
    }
    static initCallbackToRemoveCookieBannerTabIndex() {
        this.onCookieConsentAdded(() => {
            this.removeCookieBannerTabIndex();
        });
    }
    static removeCookieBannerTabIndex() {
        $('#onetrust-banner-sdk').attr('tabindex', -1);
    }
}
