import '../polyfills';
import './components/sentry';
import $ from 'jquery';
import AOS from 'aos';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'jquery-validation';
import 'jquery-mousewheel';
import 'jquery-steps/build/jquery.steps';
import InputsLogic from '../components/inputs-logic';
import AgeGate from './components/age-gate';
import Modals from './components/modals';
import NestedModals from './components/nested-modals';
import DataLayer from './components/data-layer';
import GaPromotionTracking from './components/ga-promotion-tracking';
import ScrollDirection from './components/scroll-direction';
import PasswordVisibilityToggle from './components/password-visibility-toggle';
import AjaxModal from './components/ajax-modal';
import AjaxForm from './components/ajax-form';
import PasswordReset from './components/password-reset';
import FormValidate from '../components/form-validate';
import BrowserUpdate from './components/browser-update';
import FadedScroll from './components/faded-scroll';
import LegalInfoModal from './components/legal-info-modal';
import SegmentationConsentModal from './components/segmentation-consent-modal';
import Select2Forms from '../components/select';
import CookieConsentActions from './components/cookie-consent-actions';
import AjaxButton from './components/ajax-button';
import InputFilter from './components/input-filter';
import Navbar from '../components/navbar';
import NavbarPromo from './components/navbar-promo';
import LazyImages from '../components/lazy-images';
import AnchorHashScrollTo from './components/anchor-hash-scroll-to';
import ScrollToByUrl from './components/scroll-to-by-url';
import Toast from '../components/toasts';
import BackgroundImage from '../components/background-image';
import ProfileUpdateModal from './components/profile-update-modal';
import CodeInput from './components/code-input';
import DisableOnTargetValue from './components/disable-on-target-value';
import SwitchElements from './components/switch-elements';
import SelectAllInput from './components/select-all-input';
import '../../scss/styles.scss';
import GaTracking from './components/ga-tracking';
import BrowserBackButton from './components/browser-back-button';
import FormLoading from './components/form-loading';
import ContentSameSize from './components/content-same-size';
import { SetInputValueOnTargetValue } from './components/set-input-value-on-target-value';
import SliderWithTwoPerSlideMobile from './components/slider-with-two-per-slide-mobile';
import CheckboxGaEvents from '../components/checkbox-ga-events';
import DeviceAssignModal from '../components/device-assign-modal';
import KeepFocusAfterRefresh from '../components/keep-focus-after-refresh';
$(() => {
    GaTracking.init();
    GaPromotionTracking.init();
    Navbar.init();
    NavbarPromo.init();
    LazyImages.init();
    ScrollDirection.init();
    PasswordReset.init();
    BrowserUpdate.init();
    Select2Forms.init();
    CookieConsentActions.init();
    AjaxButton.init();
    InputsLogic.init();
    InputFilter.init();
    Toast.init();
    BackgroundImage.init();
    NestedModals.init();
    CodeInput.init();
    SelectAllInput.init();
    DisableOnTargetValue.init();
    SwitchElements.init();
    BrowserBackButton.init();
    FormLoading.init();
    ContentSameSize.init();
    SetInputValueOnTargetValue.init();
    SliderWithTwoPerSlideMobile.init();
    CheckboxGaEvents.init();
    DeviceAssignModal.init();
    KeepFocusAfterRefresh.init();
    AOS.init({
        once: true,
    });
    /**
     * Create AjaxForm instance
     */
    new AjaxForm($('.js__ajax-form'));
    Modals.init();
    AjaxModal.init();
    ProfileUpdateModal.init();
    SegmentationConsentModal.init();
    LegalInfoModal.init();
    /**
     * Init FormValidate
     */
    FormValidate.init();
    /**
     * Init DataLayer instance
     */
    DataLayer.init();
    /**
     * Create PasswordVisibilityToggle instance
     */
    PasswordVisibilityToggle.init();
    /**
     * Create new instance for age gate modal
     */
    AgeGate.init();
    /**
     * Init faded scroll ends
     */
    FadedScroll.init();
    ScrollToByUrl.init();
    AnchorHashScrollTo.init();
    $(document).ajaxError((event, jqXHR) => {
        const response = jqXHR.responseJSON;
        if (response && (response.error === 'age_not_verified_error' || response.error === 'phone_not_verified_error')) {
            window.location.assign(response.redirect_url);
        }
    });
});
$('[data-toggle="popover"]').popover();
