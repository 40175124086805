import $ from 'jquery';
import LazyImages from './lazy-images';
import { showButtonLoader, toggleButtonLoader } from '../utils/button-loader';
import GaTracking from '../core/components/ga-tracking';
import Modals from '../core/components/modals';
export default class DeviceAssignModal {
    static $assignInitBtn = $('.js__assign-device-init');
    static $modal = $('.js__device-assign-modal').last();
    static $form = $('.js__assign-device-form');
    static $loader = $('.js__loader-prototype');
    static $tradeInDeviceSelectSection = $('.js__trade-in-device-select-section');
    static assignDeviceContentEndpoint = '';
    static markDevicePopupEndpoint = '';
    static loading = false;
    static init() {
        if ((this.$assignInitBtn.length && this.$modal.length) || this.$form) {
            this.bindEvents();
        }
    }
    static bindEvents() {
        if (this.$modal.hasClass('js__modal-open-on-load')) {
            this.assignDeviceContentEndpoint = this.$modal.data('url');
            this.markDevicePopupEndpoint = this.$modal.data('mark-url');
        }
        this.$assignInitBtn.on('click', e => {
            const $target = $(e.currentTarget);
            this.assignDeviceContentEndpoint = $target.data('url');
            this.showModal(this.$modal);
            this.$modal.on('hidden.bs.modal', e => {
                $target.trigger('focus');
            });
        });
        this.$modal.on('shown.bs.modal', () => {
            this.getContent(this.assignDeviceContentEndpoint);
            if (this.markDevicePopupEndpoint) {
                $.post(this.markDevicePopupEndpoint);
            }
        });
        this.$modal.on('hidden.bs.modal', () => $('.js__modal-content').html(this.$loader.clone()[0]));
        $('body').on('submit', '.js__assign-device-form', e => this.assignDevice(e));
    }
    static showModal(modal) {
        modal.modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
        });
    }
    static getContent(url) {
        const $container = $('.js__modal-content');
        if (!this.loading) {
            this.loading = true;
            $.get(url)
                .done(res => {
                $container.html(res);
                this.loading = false;
                Modals.trackModalsFlow($container);
            })
                .fail(() => window.location.reload());
        }
    }
    static assignDevice(e) {
        e.preventDefault();
        const $container = $('.js__modal-content').length ? $('.js__modal-content') : $('.js__device-assign-content');
        const $form = $('.js__assign-device-form');
        if (!this.loading) {
            this.loading = true;
            $.ajax({
                url: $form.attr('action'),
                method: $form.attr('method'),
                data: $form.serialize(),
                beforeSend: () => {
                    $container.html(this.$loader[0]);
                    showButtonLoader($('.js__assign-device'));
                },
                success: formData => {
                    if ($form.data('reload-on-success')) {
                        window.location.reload();
                    }
                    else {
                        $container.html(formData);
                        LazyImages.update();
                        this.dataLayerEvents(formData);
                    }
                    if ($('.js__device-assign-success').length) {
                        this.$modal.on('hide.bs.modal', e => {
                            e.preventDefault();
                            this.endAssign();
                        });
                    }
                },
                error: formData => {
                    if ($form.data('replace-template-on-error')) {
                        $container.html(formData.responseText);
                        LazyImages.update();
                    }
                    this.dataLayerEvents(formData.responseText);
                    this.loading = false;
                },
            }).done(() => {
                this.loading = false;
            });
        }
    }
    static endAssign() {
        toggleButtonLoader($('.js__device-assign-success').parent().find('button'), true);
        window.location.reload();
    }
    static dataLayerEvents(formData) {
        const isSuccessTemplate = $(formData).find('.js__device-assign-success').length;
        if (isSuccessTemplate) {
            if (this.$tradeInDeviceSelectSection.length) {
                GaTracking.pushDataLayer({
                    event: 'TRADEIN_NEW',
                    clickPlace: 'connect_device_register_click',
                    acquisitionPlace: this.$tradeInDeviceSelectSection.data('trade-in-referer'),
                });
            }
            GaTracking.pushDataLayer({
                event: 'popupDisplay',
                popupDisplayContent: 'Device assign success',
            });
        }
        else {
            GaTracking.pushDataLayer({
                event: 'systemError',
                errorType: 'form assign device',
                errorMessage: 'Device could not be added',
            });
        }
    }
}
