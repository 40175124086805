import $ from 'jquery';
export default class KeepFocusAfterRefresh {
    static $element = $('.js__keep-focus-after-refresh');
    static init() {
        this.setFocus();
        this.$element.on('keydown', event => {
            if (event.key === 'Enter' || event.key === ' ') {
                const id = $(event.currentTarget).data('focus-id');
                sessionStorage.setItem('keepFocus', id);
            }
        });
    }
    static setFocus() {
        const keepFocusId = sessionStorage.getItem('keepFocus');
        sessionStorage.removeItem('keepFocus');
        const $element = $(`[data-focus-id="${keepFocusId}"]`);
        $element.trigger('focus');
    }
}
