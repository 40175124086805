import $ from 'jquery';
export default class PasswordVisibilityToggle {
    static init($root = $('body')) {
        const $element = $root.find('.js__password-visibility-toggle');
        $element.on('click', event => {
            this.togglePasswordVisibilityClickHandler(event);
        });
        $element.on('keydown', event => {
            if (event.key === 'Enter' || event.key === ' ') {
                this.togglePasswordVisibilityClickHandler(event);
            }
        });
    }
    static togglePasswordVisibilityClickHandler(e) {
        const $target = $(e.currentTarget);
        const $input = $target.siblings('input');
        if (!$input.prop('disabled')) {
            this.togglePasswordVisibility($input, $target.find('.nbw-icon'));
        }
    }
    static togglePasswordVisibility($input, $icon) {
        $input.attr('type', $input.attr('type') === 'text' ? 'password' : 'text');
        $icon.toggleClass('nbw-icon-eye').toggleClass('nbw-icon-eye-slash');
    }
}
